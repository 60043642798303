import React, { useEffect } from 'react';

import { accountTypes } from 'constants/invoice';
import { formatCurrency } from 'utils/formatAmount';
import { formatDate } from 'utils/dateUtils';
import { paymentMethods } from 'constants/paymentMethod';
import { useFetchApiGet } from 'common/reduxutils';
import BusinessInfo from 'components/Invoice/BusinessInfo';
import InvoiceBody from 'components/Invoice/InvoiceBody';
import InvoiceFooter from 'components/Invoice/Footer';
import InvoiceInfo from 'components/Invoice/InvoiceInfo';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import Printable from 'components/Printable';
import Square from 'components/Invoice/Square';
import UserInfo from 'components/Invoice/UserInfo';
import apiCall from 'apiCalls/business';

const InvoiceItemDetail = ({ business_id, item }) => {
  const {
    data: companyInfo,
    load: fetchBusinessInfo,
    isLoading,
  } = useFetchApiGet(apiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchBusinessInfo({ id: business_id });
  }, [business_id]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const paymentAccount = {
    ...item.payment_account,
    account_type: accountTypes.PAYMENT,
    registration_number: companyInfo?.registration_number,
  };
  const payTo = item.payment_type === 'inflow' ? paymentAccount : item.user;
  const payFrom = item.payment_type === 'inflow' ? item.user : paymentAccount;

  return (
    <Printable
      title={`Payment Voucher ${item.code}`}
      HeaderComponent={({ currentPrintPage, totalPrintPage }) => (
        <>
          <div className="flex items-center justify-between">
            <div className="flex-1">
              <BusinessInfo data={companyInfo} />
            </div>
            <Square invoiceName="Payment Voucher" />
          </div>

          <hr className="my-5" />

          <div className="grid grid-cols-2 gap-2">
            <div>
              <div>
                <strong>Pay From:</strong>
                <UserInfo
                  user={payFrom}
                  paymentAccountDisplayName={companyInfo.company_name}
                  invoiceData={item}
                />
              </div>
              <br />
              <div>
                <strong>Pay To:</strong>
                <UserInfo
                  user={payTo}
                  paymentAccountDisplayName={companyInfo.company_name}
                  invoiceData={item}
                />
              </div>
            </div>
            <InvoiceInfo>
              <div className="grid grid-cols-3 gap-2">
                <div>
                  <p>
                    <b>Invoice No:</b>
                  </p>
                  <p>Date:</p>
                  <p>Reference No:</p>
                  {totalPrintPage > 1 ? <p>Page:</p> : null}
                </div>
                <div className="col-span-2">
                  <p>
                    <b>{item.code}</b>
                  </p>
                  <p>{formatDate(item.created)}</p>
                  <p>{item.reference_number || '-'}</p>
                  {totalPrintPage > 1 ? (
                    <p>
                      {currentPrintPage} of {totalPrintPage}
                    </p>
                  ) : null}
                </div>
              </div>
            </InvoiceInfo>
          </div>
        </>
      )}
      DataTableComponent={({ items }) => (
        <InvoiceBody
          itemsData={items}
          columns={[
            {
              title: 'Particular',
              dataIndex: 'particular',
            },
            {
              title: 'Amount',
              dataIndex: 'amount',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Payment Mode',
              dataIndex: 'payment_method',
              render: value => paymentMethods[value],
              align: 'center',
            },
            {
              title: 'Tax',
              dataIndex: 'tax',
              align: 'right',
              render: value => formatCurrency(value),
            },
          ]}
        />
      )}
      SummaryComponent={() => (
        <InvoiceFooter item={{ ...item, total_amount: item.amount }} />
      )}
      items={item.tx_items}
    />
  );
};

export default InvoiceItemDetail;
