import DataTable from 'common/ui/DataTable';
import useTenant from 'components/use-tenant';
import XModal from 'components/XModal';
import { Link } from 'react-router-dom';
import { formatNumber } from 'utils/formatAmount';
import { getTenantUrl } from 'utils/urlHelpers';

const sharedTableColumns = ({ business_id }) => [
  {
    title: 'Product',
    dataIndex: 'product',
    render: product => (
      <Link to={getTenantUrl(business_id, `/products/${product.id}`)}>
        {product.name}
      </Link>
    ),
  },
  {
    title: 'Product ID',
    dataIndex: 'product',
    render: product => product.code,
  },
  {
    title: 'Particular',
    dataIndex: 'particular',
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'right',
    render: value => formatNumber(value),
  },
];

const actionColumnData = {
  title: 'Action',
  key: 'action',
};

const DeliveryOrderItemDataTable = ({
  dataSource = [],
  renderEditItemForm,
}) => {
  const { business_id } = useTenant();

  const getActionColumn = () => {
    if (!renderEditItemForm) {
      return [];
    }

    return [
      {
        ...actionColumnData,
        render: (text, record) =>
          renderEditItemForm && (
            <XModal
              title={'Edit item'}
              onOk={() => {
                reload();
              }}
            >
              {renderEditItemForm(record.id)}
            </XModal>
          ),
      },
    ];
  };

  const columns = [
    ...sharedTableColumns({ business_id }),
    ...getActionColumn(),
  ];

  return (
    <DataTable
      rowKey="id"
      columns={columns}
      dataSource={dataSource}
      totalItems={dataSource.length}
      pagingEnabled={false}
    />
  );
};

export default DeliveryOrderItemDataTable;
