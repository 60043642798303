import apiCall from 'apiCalls/incomeType';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const IncomeTypeListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Income Types"
      namespace="income-types"
      searchOptions={{ tooltip: false }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const IncomeTypeDetailPage = () => (
  <DetailPage
    title="Income Detail"
    namespace="income-types"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
  />
);

export const IncomeTypeEditPage = () => (
  <EditPage
    title="Income Type"
    namespace="income-types"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);

export const IncomeTypeCreatePage = () => (
  <CreatePage
    title="Income Types"
    namespace="income-types"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);
