import { Button, Card, Space, Typography } from 'antd';
import { chunk, flow } from 'lodash/fp';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { mapWithIndex } from 'utils/lodashFP';

const { Title } = Typography;

const Page = ({ header, table, summary }) => {
  return (
    <div className="m-auto p-10 flex flex-col flex-1 justify-between">
      <div className="leading-3">{header}</div>
      {table}
      <div style={{ flexGrow: 1 }} />
      {summary}
    </div>
  );
};

const Printable = ({
  title,
  HeaderComponent,
  DataTableComponent,
  SummaryComponent,
  items,
  chunkSize = 10,
}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: title,
    bodyClass: 'm-0 p-0',
  });
  const totalPrintPage = Math.ceil(items.length / chunkSize);

  return (
    <>
      <Card
        title={<Title level={3}>{title}</Title>}
        extra={
          <Space>
            <Button>
              <Link onClick={handlePrint}>Print / Download</Link>
            </Button>
          </Space>
        }
        className="invoices flex flex-col"
      >
        <Page
          header={<HeaderComponent currentPrintPage={1} totalPrintPage={1} />}
          table={<DataTableComponent items={items} />}
          summary={<SummaryComponent />}
        />
      </Card>
      <div className="element-to-print" ref={componentRef}>
        {flow(
          chunk(chunkSize),
          mapWithIndex((chunk, index) => {
            return (
              <>
                <Card key={index} className="page flex flex-col">
                  <Page
                    header={
                      <HeaderComponent
                        currentPrintPage={index + 1}
                        totalPrintPage={totalPrintPage}
                      />
                    }
                    table={<DataTableComponent items={chunk} />}
                    summary={
                      index + 1 == totalPrintPage ? <SummaryComponent /> : null
                    }
                  />
                </Card>
                {index + 1 < totalPrintPage ? (
                  <div className="separator" />
                ) : null}
              </>
            );
          })
        )(items)}
      </div>
    </>
  );
};

export default Printable;
