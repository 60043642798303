import { businessLayout } from 'constants/invoice';

const BusinessInfo = ({ data, variant = businessLayout.SIMPLE }) => {
  return (
    <div>
      <p className="">
        <b>{data.company_name}</b>
      </p>
      {data.registration_number && (
        <p className="!mt-0">
          <b>({data.registration_number})</b>
        </p>
      )}
      <div className="mt-2">
        <p>{data.address1}</p>
        <p>{data.address2}</p>
        <p>{data.address3}</p>
        {variant === businessLayout.SIMPLE && (
          <>{data.phone && <p>Tel: {data.phone}</p>}</>
        )}
      </div>
      {variant === businessLayout.DETAILED && (
        <div className="flex gap-4 my-2">
          <div>
            <p>Phone</p>
            <p>Email</p>
            <p>TIN</p>
          </div>
          <div className="col-span-2">
            <p>: {data.phone || '-'}</p>
            <p>: {data.email || '-'}</p>
            <p>: {data.tax_number || '-'}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessInfo;
