import { useQuery } from '@tanstack/react-query';
import { Divider } from 'antd';
import apiCall from 'apiCalls/business';
import MetaDecorator from 'components/MetaDecorator';
import useTenant from 'components/use-tenant';

const useBusinessQuery = business_id => {
  return useQuery({
    queryKey: [apiCall.detail.queryKey, business_id],
    queryFn: () => {
      if (business_id) {
        return apiCall.detail.queryFn({ id: business_id });
      }

      return Promise.resolve('');
    },
  });
};

const BusinessHome = ({ name }) => {
  const { business_id } = useTenant();
  const { data = {}, isLoading } = useBusinessQuery(business_id);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!data) {
    return null;
  }

  const hasCompanyAddress = data.address1 || data.address2 || data.address3;

  return (
    <>
      <MetaDecorator title={'Home'} />
      <h1>Hi {name} 👋</h1>
      <p>
        Welcome to <b>{data.name}</b>.
      </p>
      <p>Company name: {data.company_name}</p>
      {data.registration_number && (
        <p>Registration No: {data.registration_number}</p>
      )}
      {data.phone && <p>Tel: {data.phone}</p>}
      {data.email && <p>Email: {data.email}</p>}
      {data.tax_number && <p>TIN: {data.tax_number}</p>}
      {hasCompanyAddress && (
        <>
          Address:
          {data.address1 && <p>{data.address1}</p>}
          {data.address2 && <p>{data.address2}</p>}
          {data.address3 && <p>{data.address3}</p>}
        </>
      )}
      <div>
        <>
          <Divider orientation="left"></Divider>
        </>
      </div>
    </>
  );
};

export default BusinessHome;
