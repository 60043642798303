import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Input, InputNumber, notification } from 'antd';
import { map } from 'lodash/fp';
import { useEffect } from 'react';

import deliveryOrderApiCall from 'apiCalls/deliveryOrder';
import deliveryOrderItemApiCall from 'apiCalls/deliveryOrderItem';
import productApiCall from 'apiCalls/product';
import DebounceSelect from 'common/ui/DebouceSelect';
import useTenant from 'components/use-tenant';
import { modalFormLayout } from 'utils/formConfig';
import { ensureFormData } from 'utils/formData';

const nestedFields = ['product_variant', 'product'];

const useDeliveryOrderItemQuery = (business_id, id) => {
  return useQuery({
    queryKey: [deliveryOrderItemApiCall.detail.queryKey, id],
    queryFn: () => {
      if (id) {
        return deliveryOrderItemApiCall.detail.queryFn({ business_id, id });
      }

      return Promise.resolve('');
    },
  });
};

const DeliveryOrderItemForm = ({ form, name, closeModal, params }) => {
  const { deliveryOrder, id } = params;
  const queryClient = useQueryClient();
  const { business_id } = useTenant();

  const { data: deliveryOrderItem = {} } = useDeliveryOrderItemQuery(
    business_id,
    id
  );

  const { mutate: createItem } = useMutation({
    mutationFn: deliveryOrderItemApiCall.create.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([deliveryOrderApiCall.detail.queryKey]);
      notification.open({ message: 'Saved' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: error.data.detail,
        duration: 10,
      });
    },
  });

  const { mutate: updateItem } = useMutation({
    mutationFn: deliveryOrderItemApiCall.edit.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([deliveryOrderApiCall.detail.queryKey]);
      queryClient.invalidateQueries([deliveryOrderItemApiCall.detail.queryKey]);

      notification.open({ message: 'Saved' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: error.data.detail,
        duration: 10,
      });
    },
  });

  useEffect(() => {
    form.setFieldsValue(ensureFormData(deliveryOrderItem, nestedFields));
  }, [deliveryOrderItem]);

  return (
    <Form
      {...modalFormLayout}
      form={form}
      name={name || `deliveryOrder_item_form_${id}`}
      onFinish={values => {
        const payload = {
          ...params,
          ...values,
          delivery_order: deliveryOrder,
          discount_percentage: values.discount_percentage || 0,
          tax: values.tax || 0,
        };

        if (id) {
          updateItem({ business_id, id, payload });
        } else {
          createItem({ business_id, payload });
        }

        closeModal();
        form.resetFields();
      }}
      scrollToFirstError
    >
      <Form.Item name="product" label="Product" rules={[{ required: true }]}>
        <DebounceSelect
          apiCall={productApiCall.list}
          placeholder="Select product"
          displayFn={map(product => ({
            value: product.id,
            label: product.name,
            ...product,
          }))}
          onSelect={(_selectedProductId, selectedProduct) =>
            form.setFieldsValue({
              unit: selectedProduct.unit,
              product: selectedProduct.id,
            })
          }
          params={{ business_id }}
        />
      </Form.Item>

      <Form.Item name="particular" label="Particular">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="unit" label="Unit">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="quantity" label="Quantity" rules={[{ required: true }]}>
        <InputNumber placeholder="" precision={2} step={1} />
      </Form.Item>
    </Form>
  );
};

export default DeliveryOrderItemForm;
