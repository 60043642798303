import { Button, Tag } from 'antd';
import {
  statusColors as paymentStatusColors,
  statusLabels as paymentStatusLabels,
} from 'constants/status';
import { Link } from 'react-router-dom';
import { formatDate, formatDateTime } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';
import { getTenantUrl } from 'utils/urlHelpers';

export const columnsGenerator = ({ business_id }) => [
  {
    title: 'Date',
    dataIndex: 'date',
    render: value => formatDate(value),
  },
  {
    title: 'PV No.',
    dataIndex: 'code',
    align: 'center',
  },
  {
    title: 'Cash & Bank Book Name',
    dataIndex: 'payment_account',
    align: 'center',
    render: value => value?.name,
  },
  {
    title: 'Particulars',
    dataIndex: 'user',
    align: 'center',
    render: (value, record) =>
      `${record.payment_type == 'inflow' ? 'Received from ' : 'Paid to'} ${
        value?.name
      }`,
  },
  {
    title: 'Money In',
    dataIndex: 'amount',
    align: 'right',
    render: (value, record) =>
      record.payment_type == 'inflow' ? formatCurrency(value) : '',
  },
  {
    title: 'Payment',
    dataIndex: 'amount',
    align: 'right',
    render: (value, record) =>
      record.payment_type == 'outflow' ? formatCurrency(value) : '',
  },
  {
    title: 'Created',
    dataIndex: 'created',
    render: value => formatDateTime(value),
  },
  {
    title: 'Modified',
    dataIndex: 'modified',
    render: value => formatDateTime(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => {
      return (
        <Tag color={paymentStatusColors[status]}>
          {paymentStatusLabels[status]}
        </Tag>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'id',
    render: (id, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={getTenantUrl(business_id, `/payment-transactions/${id}`)}>
            View
          </Link>
        </Button>
        <Button
          shape="round"
          disabled={record.status == 1002 || record == 1003}
        >
          <Link
            to={getTenantUrl(business_id, `/payment-transactions/${id}/edit`)}
          >
            Edit
          </Link>
        </Button>
        <Button shape="round">
          <Link
            to={getTenantUrl(
              business_id,
              `/payment-transactions/${id}/invoice`
            )}
          >
            PV
          </Link>
        </Button>
      </Button.Group>
    ),
  },
];
