import apiCall from 'apiCalls/income';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import ItemForm from './forms/IncomeForm';
import InvoiceItemDetail from './invoice';

export const IncomeListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Incomes"
      namespace="incomes"
      searchOptions={{ tooltip: 'Enter invoice number, username or email' }}
      filterOptions={{ filters: ['date', 'branches'] }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const IncomeDetailPage = () => (
  <DetailPage
    title="Income Detail"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
  />
);

export const IncomeEditPage = () => (
  <EditPage
    title="Income"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);

export const IncomeCreatePage = () => (
  <CreatePage
    title="Incomes"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);

export const IncomeInvoicePage = () => (
  <DetailPage
    title="Invoice"
    apiCall={apiCall}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
  />
);
