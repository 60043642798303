import { Button, Card, Descriptions, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/dateUtils';
import { getTenantUrl } from 'utils/urlHelpers';
import DeliveryOrderItemDataTable from './ItemDataTable';
const { Title } = Typography;

const ItemDetail = ({ business_id, item }) => {
  return (
    <>
      <Card
        title={<Title level={3}>Delivery Order Detail</Title>}
        extra={
          <Space>
            <Button>
              <Link
                to={getTenantUrl(
                  business_id,
                  `/delivery-orders/${item.id}/edit`
                )}
              >
                Edit
              </Link>
            </Button>
            <Button>
              <Link
                to={getTenantUrl(
                  business_id,
                  `/delivery-orders/${item.id}/invoice`
                )}
              >
                View invoice
              </Link>
            </Button>
          </Space>
        }
      >
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Customer">
            {item.user ? item.user.name : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Billing Address Line 1">
            {item.billing_address}
          </Descriptions.Item>
          <Descriptions.Item label="Billing Address Line 2">
            {item.billing_address2}
          </Descriptions.Item>
          <Descriptions.Item label="Billing Address Line 3">
            {item.billing_address3}
          </Descriptions.Item>

          <Descriptions.Item label="Contact Person">
            {item.contact_person}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {item.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Email Address">
            {item.customer_email}
          </Descriptions.Item>

          <Descriptions.Item label="Shipping Address">
            {item.shipping_address}
          </Descriptions.Item>
          <Descriptions.Item label="Shipping Contact">
            {item.shipping_name}
          </Descriptions.Item>
          <Descriptions.Item label="Shipping Phone Number">
            {item.shipping_phone_number}
          </Descriptions.Item>

          <Descriptions.Item label="Invoice No.">{item.code}</Descriptions.Item>
          <Descriptions.Item label="Date">{item.date}</Descriptions.Item>
          <Descriptions.Item label="Branch">
            {item.branch?.name}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Card
        title={<Title level={3}>Delivery Order Items</Title>}
        className="my-5"
      >
        <DeliveryOrderItemDataTable
          dataSource={item.delivery_order_items || []}
          quotation={item}
        />
      </Card>

      <Card className="my-5">
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Notes">{item.notes}</Descriptions.Item>
          <Descriptions.Item label="Personal Notes">
            {item.notes_admin}
          </Descriptions.Item>
          <Descriptions.Item label="Attachment">
            {item.file ? (
              <a href={item.file} target="_blank" rel="noreferrer">
                Open Attachment
              </a>
            ) : null}
          </Descriptions.Item>
          <Descriptions.Item label="Created Date">
            {formatDateTime(item.created)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated Date">
            {formatDateTime(item.modified)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ItemDetail;
