import { accountTypes, businessLayout } from 'constants/invoice';

const UserInfo = ({
  user,
  invoiceData = {},
  paymentAccountDisplayName,
  variant = businessLayout.SIMPLE,
}) => {
  const isPaymentAccount = user?.account_type === accountTypes.PAYMENT;
  const displayName = isPaymentAccount ? paymentAccountDisplayName : user.name;

  const phoneNumber = invoiceData.phone_number || user.phone;
  const email = invoiceData.customer_email || user.email;
  const overrideAddress = !!invoiceData.billing_address;

  return (
    <div>
      <p className="">
        <b>{displayName.toUpperCase()}</b>
      </p>
      {user.registration_number && (
        <p className="!mt-0 semibold">({user.registration_number})</p>
      )}
      {isPaymentAccount && <p className="semibold">{user?.code}</p>}
      {!isPaymentAccount && (
        <>
          {overrideAddress ? (
            <>
              <p>{invoiceData.billing_address}</p>
              <p>{invoiceData.billing_address2}</p>
              <p>{invoiceData.billing_address3}</p>
            </>
          ) : (
            <>
              <p>{user.address1}</p>
              <p>{user.address2}</p>
              <p>{user.address3}</p>
            </>
          )}
          {variant === businessLayout.SIMPLE && phoneNumber && (
            <p>Tel: {phoneNumber}</p>
          )}
          {variant === businessLayout.DETAILED && (
            <div className="flex gap-4 my-2">
              <div>
                <p>Phone</p>
                <p>Email</p>
                <p>TIN</p>
              </div>
              <div className="col-span-2">
                <p>: {phoneNumber || '-'}</p>
                <p>: {email || '-'}</p>
                <p>: {user.tax_number || '-'}</p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserInfo;
