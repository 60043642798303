import { Card, Descriptions } from 'antd';
import userApiCall from 'apiCalls/user/detail';
import statementApiCall from 'apiCalls/userStatement';
import { useFetchApiGet } from 'common/reduxutils';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { StatementPage } from 'components/statement/StatementPage';
import useTenant from 'components/use-tenant';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { formatCurrency } from 'utils/formatAmount';
import { getTenantUrl } from 'utils/urlHelpers';
import { statementColumns } from './config';

export const SupplierStatementPage = props => {
  const { business_id } = useTenant();
  const { user_id } = useParams();
  const [baseSearchParams] = useSearchParams();
  const params = { business_id, status__in: '1001,1002' };

  return (
    <StatementPage
      ItemCard={ItemCard}
      params={params}
      statementUrl={getTenantUrl(
        business_id,
        `/suppliers/${user_id}/statement/print?${baseSearchParams.toString()}`
      )}
      backUrl={getTenantUrl(business_id, '/suppliers')}
      statementApiCall={statementApiCall}
      title="Balance Statement"
      statementColumns={() => statementColumns({ business_id })}
      {...props}
    />
  );
};

const ItemCard = ({ balance }) => {
  const { business_id } = useTenant();
  const { user_id } = useParams();
  const {
    data: item = {},
    load: fetchUser,
    isLoading: isUserLoading,
  } = useFetchApiGet(userApiCall, { resourceName: 'items' });

  useEffect(() => {
    if (business_id && user_id) {
      fetchUser({ business_id, id: user_id });
    }
  }, [business_id, user_id]);

  if (isUserLoading) return <LoadingSpinner />;

  return (
    <Card>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Debtor ID">
          {item.creditor_code}
        </Descriptions.Item>
        <Descriptions.Item label="Company Name">{item.name}</Descriptions.Item>
        <Descriptions.Item label="Address">
          {item.address1}
          {item.address2 && (
            <>
              <br />
              {item.address2}
            </>
          )}
          {item.address3 && (
            <>
              <br />
              {item.address3}
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Balance to Date">
          {formatCurrency(balance)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
