import React, { useEffect } from 'react';

import { businessLayout } from 'constants/invoice';
import { formatCurrency } from 'utils/formatAmount';
import { formatDate } from 'utils/dateUtils';
import { useFetchApiGet } from 'common/reduxutils';
import BusinessInfo from 'components/Invoice/BusinessInfo';
import InvoiceBody from 'components/Invoice/InvoiceBody';
import InvoiceFooter from 'components/Invoice/Footer';
import InvoiceInfo from 'components/Invoice/InvoiceInfo';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import Printable from 'components/Printable';
import Square from 'components/Invoice/Square';
import UserInfo from 'components/Invoice/UserInfo';
import apiCall from 'apiCalls/business';

const InvoiceItemDetail = ({ business_id, item }) => {
  const {
    data: companyInfo,
    load: fetchBusinessInfo,
    isLoading,
  } = useFetchApiGet(apiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchBusinessInfo({ id: business_id });
  }, [business_id]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Printable
      title={`Expense Invoice ${item.code}`}
      HeaderComponent={({ currentPrintPage, totalPrintPage }) => (
        <>
          <div className="flex items-center justify-between">
            <div className="flex-1">
              <UserInfo
                user={item.user}
                paymentAccountDisplayName="Cash Expense"
                invoiceData={item}
                variant={businessLayout.SIMPLE}
              />
            </div>
            <Square invoiceName="Expense Invoice" />
          </div>

          <hr className="my-5" />

          <div className="grid grid-cols-2 gap-2">
            <BusinessInfo
              data={companyInfo}
              variant={businessLayout.DETAILED}
            />
            <InvoiceInfo>
              <div className="grid grid-cols-3 gap-2">
                <div>
                  <p>
                    <b>Invoice No:</b>
                  </p>
                  <p>Date:</p>
                  <p>Reference No.:</p>
                  <p>Branch:</p>
                  {totalPrintPage > 1 ? <p>Page:</p> : null}
                </div>
                <div className="col-span-2">
                  <p>
                    <b>{item.code}</b>
                  </p>
                  <p>{formatDate(item.created)}</p>
                  <p>{item.reference_number || '-'}</p>
                  <p>{item.branch?.name || '-'}</p>
                  {totalPrintPage > 1 ? (
                    <p>
                      {currentPrintPage} of {totalPrintPage}
                    </p>
                  ) : null}
                </div>
              </div>
            </InvoiceInfo>
          </div>
        </>
      )}
      DataTableComponent={({ items }) => (
        <InvoiceBody
          itemsData={items}
          columns={[
            {
              title: 'Particular',
              dataIndex: 'particular',
            },
            {
              title: 'Quantity',
              dataIndex: 'quantity',
              align: 'right',
            },
            {
              title: 'Price',
              dataIndex: 'price',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Amount',
              dataIndex: 'amount',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Tax',
              dataIndex: 'tax',
              align: 'right',
              render: value => formatCurrency(value),
            },
          ]}
        />
      )}
      SummaryComponent={() => (
        <InvoiceFooter item={{ ...item, total_amount: item.amount }} />
      )}
      items={item.expense_items}
    />
  );
};

export default InvoiceItemDetail;
