import { formatDate } from 'utils/dateUtils';

const InvoiceInfo = ({ children }) => {
  return <div>{children}</div>;
};

export const InvoiceInfoWrapper = ({
  item,
  name,
  totalPrintPage = 0,
  currentPrintPage = 1,
}) => {
  return (
    <div className="">
      <table className="table w-full [border-spacing:0.75rem]">
        <tbody>
          <tr className="my-3">
            <td>
              <b>{name || 'Invoice'} No:</b>
            </td>
            <td>
              <b>{item.code}</b>
            </td>
          </tr>
          <tr className="my-3">
            <td>
              <b>Date:</b>
            </td>
            <td>{formatDate(item.date)}</td>
          </tr>
          <tr className="my-3">
            <td>
              <b>Branch:</b>
            </td>
            <td>{item.branch?.name || '-'}</td>
          </tr>
          {totalPrintPage > 1 ? (
            <tr className="my-3">
              <td className="text-left">
                <b>Page:</b>
              </td>
              <td className="text-left">
                {currentPrintPage} of {totalPrintPage}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceInfo;
