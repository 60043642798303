import { formatCurrency } from 'utils/formatAmount';

const Footer = ({ item, bankNotes }) => {
  return (
    <div>
      <hr className="my-5" />

      <div className="grid grid-cols-2 gap-2">
        <div>
          {item.notes && (
            <>
              <p>
                <b>Notes: </b>
                {item.notes}
              </p>
            </>
          )}
        </div>
        <div className="flex gap-16 justify-end leading-1">
          <div>
            <p>Sub Total:</p>
            <p>Tax:</p>
            <p>Discount Given:</p>
            <p>Rounding Adjustment:</p>
            <p>Total Amount:</p>
          </div>
          <div className="pr-5">
            <p>{formatCurrency(item.sub_total)}</p>
            <p>{formatCurrency(item.tax)}</p>
            <p>{formatCurrency(item.discount)}</p>
            <p>{formatCurrency(item.rounding_adjustment)}</p>
            <p>{formatCurrency(item.total_amount)}</p>
          </div>
        </div>
      </div>
      <div className="my-5 leading-5 footer">
        {bankNotes &&
          bankNotes.split('\n').map(note => {
            if (note.includes('Bank Account')) {
              return <p className="semibold">{note}</p>;
            }

            return <p>{note}</p>;
          })}
      </div>
    </div>
  );
};

export default Footer;
