import { UploadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Divider,
  Form,
  Input,
  notification,
  Select,
  Space,
  Upload,
} from 'antd';
import { map } from 'lodash/fp';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import branchApiCall from 'apiCalls/branch';
import orderApiCall from 'apiCalls/purchase';
import userApiCall from 'apiCalls/user/all';
import DatePicker from 'common/form/UIDatePicker';
import DebounceSelect from 'common/ui/DebouceSelect';
import XModal from 'components/XModal';
import { statusOptions } from 'constants/status';
import { useFormBlocker } from 'hooks';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import {
  convertDateFormData,
  ensureFormData,
  FormattedError,
  submitData,
  userSelectDisplayFn,
} from 'utils/formData';

import useTenant from 'components/use-tenant';
import OrderItemDataTable from '../OrderItemDataTable';
import PurchaseItemForm from './PurchaseItemForm';

const dateFields = ['shipping_estimated_arrived', 'date'];
const nestedFields = ['user', 'branch'];

const usePurchaseQuery = (business_id, id) => {
  return useQuery({
    queryKey: [orderApiCall.detail.queryKey, id],
    queryFn: () => {
      if (id) {
        return orderApiCall.detail.queryFn({ business_id, id });
      }

      return Promise.resolve('');
    },
  });
};

const PurchaseForm = ({ form, initialValues = { status: 1001 }, name }) => {
  const setShouldBlock = useFormBlocker(form);
  const navigate = useNavigate();
  const urlParams = useParams();
  const { id } = urlParams;
  const formData = Form.useWatch([], form) || {};
  const { business_id } = useTenant();
  const purchaseId = id || formData.id;
  const { data: purchase = {} } = usePurchaseQuery(business_id, purchaseId);

  const dataItem = {
    ...initialValues,
    ...purchase,
  };

  const [shouldGoBack, setShouldGoBack] = useState(false);
  const { mutate: createPurchase, isLoading: isCreating } = useMutation({
    mutationFn: orderApiCall.create.queryFn,
    onSuccess: createdPurchase => {
      if (shouldGoBack) {
        notification.open({
          type: 'success',
          message: 'Success',
        });
        navigate(-1);
      } else {
        form.setFieldsValue({
          id: createdPurchase.id,
          code: createdPurchase.code,
          status: createdPurchase.status,
        });

        notification.open({ message: 'Saved' });
      }
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const { mutate: updatePurchase, isLoading: isUpdating } = useMutation({
    mutationFn: orderApiCall.edit.queryFn,
    onSuccess: () => {
      if (shouldGoBack) {
        notification.open({
          type: 'success',
          message: 'Success',
        });
        navigate(-1);
      } else {
        notification.open({ message: 'Saved' });
      }
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  // detect changes for auto save
  useEffect(() => {
    const isAutoSave =
      !formData.id &&
      !!formData.user &&
      !!formData.branch &&
      formData.status != 1002 &&
      !!formData.date;

    if (isAutoSave) {
      setShouldBlock(false);
      form.submit();
    }
  }, [formData]);

  useEffect(() => {
    form.setFieldsValue(ensureFormData(dataItem, nestedFields, dateFields));
  }, [form, purchase]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'purchase_order_checkout_form'}
      onFieldsChange={() => setShouldBlock(!!formData.id)}
      onFinish={values => {
        setShouldBlock(false);
        if (values.id) {
          return submitData(
            updatePurchase,
            convertDateFormData(values, dateFields),
            urlParams,
            ['file']
          );
        }

        return submitData(
          createPurchase,
          convertDateFormData(values, dateFields),
          urlParams,
          ['file']
        );
      }}
      scrollToFirstError
    >
      <Form.Item name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="status" label="Status">
        <Select>
          {statusOptions.map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="user" label="Supplier" required>
        <DebounceSelect
          apiCall={userApiCall}
          initialOption={{
            id: initialValues.user?.id,
            name: initialValues.user?.name,
            account_type: initialValues.user?.account_type,
          }}
          placeholder="Select supplier account"
          params={{
            business_id,
            account_type__in: 'customer,supplier,payment',
          }}
          displayFn={userSelectDisplayFn}
          fieldNames={{ label: 'name', value: 'id' }}
          onSelect={(_selectedUserId, selectedUser) => {
            // Only pre-fill for non-payment accounts (creditor or debitor accounts)
            if (selectedUser && selectedUser.account_type != 'payment') {
              form.setFieldsValue({
                billing_address: selectedUser.address1,
                billing_address2: selectedUser.address2,
                billing_address3: selectedUser.address3,
                contact_person: selectedUser.person_in_charge,
                phone_number: selectedUser.phone,
                email: selectedUser.email,
              });
            }
          }}
        />
      </Form.Item>
      <Form.Item name="billing_address" label="Billing Address Line 1">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="billing_address2" label="Billing Address Line 2">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="billing_address3" label="Billing Address Line 3">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="email" label="Email Address">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="contact_person" label="Contact Person">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="phone_number" label="Phone number">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item label="Invoice No.">
        {dataItem.code || '[auto generated]'}
      </Form.Item>
      <Form.Item name="date" label="Date" required>
        <DatePicker format={'YYYY-MM-DD'} />
      </Form.Item>
      <Form.Item name="branch" label="Branch" required>
        <DebounceSelect
          apiCall={branchApiCall.list}
          placeholder="Select branch"
          displayFn={map(o => ({
            value: o.id,
            label: o.name,
          }))}
          params={{ business_id }}
        />
      </Form.Item>
      <Form.Item name="reference_number" label="Reference No.">
        <Input placeholder="" />
      </Form.Item>

      <Divider>Add product to order</Divider>

      <XModal title={'New item'} isReady={!!formData.id}>
        <PurchaseItemForm params={{ purchase: purchaseId }} />
      </XModal>

      <OrderItemDataTable
        dataSource={dataItem.purchase_items || []}
        renderEditItemForm={id => (
          <PurchaseItemForm params={{ purchase: purchaseId, id: id }} />
        )}
        order={dataItem}
      />

      <Form.Item name="notes" label="Notes">
        <Input.TextArea rows={5} />
      </Form.Item>
      <Form.Item name="notes_admin" label="Personal Notes">
        <Input.TextArea rows={5} />
      </Form.Item>
      <Form.Item
        name="file"
        label="Attachment"
        valuePropName="fileList "
        getValueFromEvent={e => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreating || isUpdating}
            onClick={() => {
              setShouldGoBack(true);
            }}
          >
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default PurchaseForm;
