import DataTable from 'common/ui/DataTable';
import useTenant from 'components/use-tenant';
import XModal from 'components/XModal';
import { formatCurrency, formatNumber } from 'utils/formatAmount';

const sharedTableColumns = ({ business_id }) => [
  {
    title: 'Product',
    dataIndex: 'product',
    render: product => product.name,
  },
  {
    title: 'Variant',
    dataIndex: 'product_variant',
    render: product_variant => (product_variant ? product_variant.name : null),
  },
  {
    title: 'Price per item',
    dataIndex: ['price'],
    align: 'right',
    render: (text, record) => {
      const value = formatCurrency(text);
      const productPrice =
        record.product_variant?.price || record.product?.price || 0;
      const isPriceAdjusted = record.price !== productPrice;

      return isPriceAdjusted ? (
        <span className="text-amber-700">{value}</span>
      ) : (
        value
      );
    },
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'right',
    render: value => formatNumber(value),
  },
  {
    title: 'Amount',
    dataIndex: 'sub_total',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Discount',
    dataIndex: 'discount_percentage',
    align: 'right',
    render: value => (value > 0 ? `${value}%` : '-'),
  },
  {
    title: 'Tax',
    dataIndex: 'tax',
    align: 'tax',
    render: value => (value > 0 ? formatCurrency(value) : '-'),
  },
];

const actionColumnData = {
  title: 'Action',
  key: 'action',
};

const SalesReturnItemDataTable = ({
  dataSource = [],
  renderEditItemForm,
  salesReturn,
}) => {
  const { business_id } = useTenant();

  const getActionColumn = () => {
    if (!renderEditItemForm) {
      return [];
    }

    return [
      {
        ...actionColumnData,
        render: (text, record) =>
          renderEditItemForm && (
            <XModal
              title={'Edit item'}
              onOk={() => {
                reload();
              }}
            >
              {renderEditItemForm(record.id)}
            </XModal>
          ),
      },
    ];
  };

  const columns = [
    ...sharedTableColumns({ business_id }),
    ...getActionColumn(),
  ];

  return (
    <>
      <DataTable
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        totalItems={dataSource.length}
        pagingEnabled={false}
      />

      <div className="grid grid-cols-5 gap-4 mb-5">
        <div className="col-span-3"></div>
        <div className="">
          <p>Sub Total:</p>
          <p>Tax:</p>
          <p>Discount Given:</p>
          <p>Rounding Adjustment:</p>
          <p>Total Amount:</p>
        </div>
        <div className="">
          <p>{formatCurrency(salesReturn.sub_total)}</p>
          <p>{formatCurrency(salesReturn.tax)}</p>
          <p>{formatCurrency(salesReturn.discount)}</p>
          <p>{formatCurrency(salesReturn.rounding_adjustment)}</p>
          <p>{formatCurrency(salesReturn.total_amount)}</p>
        </div>
      </div>
    </>
  );
};

export default SalesReturnItemDataTable;
