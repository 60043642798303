import { useEffect } from 'react';

import apiCall from 'apiCalls/business';
import { useFetchApiGet } from 'common/reduxutils';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import BusinessInfo from 'components/Invoice/BusinessInfo';
import InvoiceBody from 'components/Invoice/InvoiceBody';
import { InvoiceInfoWrapper } from 'components/Invoice/InvoiceInfo';
import Square from 'components/Invoice/Square';
import UserInfo from 'components/Invoice/UserInfo';
import Printable from 'components/Printable';
import { businessLayout } from 'constants/invoice';

const InvoiceItemDetail = ({ business_id, item }) => {
  const {
    data: companyInfo,
    load: fetchBusinessInfo,
    isLoading,
  } = useFetchApiGet(apiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchBusinessInfo({ id: business_id });
  }, [business_id]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Printable
      title={`Delivery Order ${item.code}`}
      HeaderComponent={({ currentPrintPage, totalPrintPage }) => (
        <>
          <div className="flex items-center justify-between">
            <div className="flex-1">
              <BusinessInfo data={companyInfo} />
            </div>
            <Square invoiceName="Delivery order" />
          </div>

          <hr className="my-5" />

          <div className="grid grid-cols-2 gap-2">
            <UserInfo
              user={item.user}
              variant={businessLayout.DETAILED}
              paymentAccountDisplayName="Cash Sales Delivery order"
              invoiceData={item}
            />
            <InvoiceInfoWrapper
              name={'Delivery Order'}
              item={item}
              totalPrintPage={totalPrintPage}
              currentPrintPage={currentPrintPage}
            />
          </div>
        </>
      )}
      DataTableComponent={({ items }) => (
        <InvoiceBody
          itemsData={items}
          columns={[
            {
              title: 'Product',
              dataIndex: 'product',
              render: product => product.name,
            },
            {
              title: 'Product ID',
              dataIndex: 'product',
              render: product => product.code,
            },
            {
              title: 'Particular',
              dataIndex: 'product',
              render: product => product.code,
            },
            {
              title: 'Unit',
              dataIndex: 'unit',
              align: 'center',
            },
            {
              title: 'Quantity',
              dataIndex: 'quantity',
              align: 'right',
            },
          ]}
        />
      )}
      SummaryComponent={() => (
        <div>
          <hr className="my-5" />

          <div className="grid grid-cols-2 gap-2">
            <div className="">
              <p className="my-3">
                <strong>Notes: </strong>
                Thank you for your order! Please check all items carefully and
                notify us of any discrepancy within 24 hours
              </p>
              {item.notes && (
                <p className="my-3">
                  <strong>Notes: </strong>
                  {item.notes}
                </p>
              )}
              <p className="my-3">
                <strong>Authorised Signature</strong>
                <br />
                <br />
                <br />
              </p>
            </div>
          </div>
          <div className="my-5 leading-5 footer">
            {item.bank_notes &&
              item.bank_notes.split('\n').map(note => {
                if (note.includes('Bank Account')) {
                  return <p className="semibold">{note}</p>;
                }

                return <p>{note}</p>;
              })}
          </div>
          <div className="grid grid-cols-2 gap-3">
            <div className="">
              <h4 className="my-2">
                <hr className="my-3 ml-0 w-1/2" />
                <strong>
                  {companyInfo.name}
                  {companyInfo.registration_number
                    ? `(${companyInfo.registration_number})`
                    : ''}
                </strong>
              </h4>
              <p className="my-2">
                This is computer generated document no signature required
              </p>
            </div>
            <div className="">
              <h4 className="my-2">
                <hr className="my-3 ml-0 w-1/2" />
                <strong>Customer Company Stamp and Signature</strong>
              </h4>
              <p className="my-2">Name:</p>
              <p className="my-2">I/C Number:</p>
            </div>
          </div>
        </div>
      )}
      items={item.delivery_order_items}
    />
  );
};

export default InvoiceItemDetail;
